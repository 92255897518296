import {
  BooleanInput,
  Edit,
  FormTab,
  NumberInput,
  TabbedForm,
  minValue,
} from 'react-admin';
import Permission from '../components/Permission';
import { PERMISSIONS } from '../constants';
import { getCompanyId } from '../utils/utils';
import { AutoCompleteSelectArrayInput } from '../components';
import { RichTextInput } from 'ra-input-rich-text';
import { Divider } from '@mui/material';

const CompanyConfig = (props) => {

  return (
    <Permission permission={PERMISSIONS.COMPANY_CONFIG}>
      <Edit
        id={getCompanyId()}
        title="Configurações"
        resource="company-config"
        mutationMode="pessimistic"
      >
        <TabbedForm>
          <FormTab label="Geral">
            <BooleanInput label="Obrigar veículos a informar odômetro" source={"vehicleOdometer"} />
            <BooleanInput label="Obrigar motoristas a informar odômetro" source={"driverOdometer"} />
            <BooleanInput label="Permitir Abastecimentos de veículos sem operação vinculada" source="isFillinWithoutOperation" />
            <NumberInput
              sx={{ width: 435 }}
              label="Intervalo obrigatório entre abastecimentos (em horas)"
              source={"intervalBetweenFillin"}
              validate={minValue(0)}
            />
          </FormTab>
          <FormTab label="Emails">
            <AutoCompleteSelectArrayInput
              label="Abastecimentos sem aprovação imediata"
              source="mailingListIds"
              references="mailing-list"
              sx={{ minWidth: 435 }}
            />
            <RichTextInput label="Mensagem" source="companyBody" />
            <Divider sx={{ width: "100%", marginBottom: 2 }} />
            <AutoCompleteSelectArrayInput
              label="Abastecimentos sem aprovação imediata (Rede)"
              source="chainMailingListIds"
              references="mailing-list"
              defaultFilter={{ isLinked: true }}
              sx={{ minWidth: 435 }}
            />
            <RichTextInput label="Mensagem" source="chainBody" />
          </FormTab>
        </TabbedForm>
      </Edit>
    </Permission>
  );
};

const resource = {
  list: CompanyConfig,
  name: 'company-config',
  permissions: [PERMISSIONS.COMPANY_CONFIG]
};

export default resource;