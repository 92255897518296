import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  FunctionField,
  NumberField,
  NumberInput,
  Pagination,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectArrayInput,
  SelectInput,
  Show,
  SimpleFormIterator,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  minLength,
  minValue,
  required,
  useChoicesContext,
  useDataProvider,
  useGetIdentity,
  useNotify,
  usePermissions,
} from "react-admin";
import { List, Permissions } from "../components";
import LazyLoadAutoCompleteInput from "../components/LazyLoadAutoCompleteInput";
import { SearchFilter } from "../components/SearchFilter";
import { PERMISSIONS } from "../constants";
import { UserRole } from "../providers/authProvider";
import { CPFInput, PhoneInput } from "../utils/Inputs";
import { dateInputFormatter, formatCellphone, formatCpf, getCompanyId } from "../utils/utils";
import { FillinDatagrid } from "./Fillin";
import { RouteDatagrid } from "./Route";
import { VehicleDatagrid } from "./Vehicle";

const editStyles = makeStyles({
  arrayInput: {
    '& p': {
      color: 'grey',
      'margin-right': '1em',
      'font-weight': 'bold',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '10px 0 0 0'
  }
})

const LimitType = {
  single: 'single',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
}

const translateLimitType = (type) => {
  switch (type) {
    case LimitType.single:
      return 'Único';
    case LimitType.daily:
      return 'Diário';
    case LimitType.weekly:
      return 'Semanal'
    case LimitType.monthly:
      return 'Mensal';
    default: return "Nenhum";
  }
}

const limitTypeChoices = [
  { id: 'single', name: 'Único' },
  { id: 'daily', name: 'Diário' },
  { id: 'weekly', name: "Semanal" },
  { id: 'monthly', name: "Mensal" }
]

const categoriesChoices = [
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
];

const statusChoices = [
  { id: 'isLiberated', name: 'Liberado' },
  { id: 'isBlocked', name: 'Bloqueado' },
  { id: 'isInactive', name: 'Inativo' },
  { id: 'isSuspended', name: 'Suspenso' },
];

const VehicleSelect = ({ formData, scopedFormData, getSource, ...props }) => {
  const { allChoices: choices, isLoading } = useChoicesContext();
  const vehicles = formData.vehicles || formData.driverVehicles;

  const selectedVehiclesIds = vehicles ? vehicles.filter((vehicle) => vehicle).map(
    vehicle => vehicle.vehicleId,
  ) : [];

  const availableVehicles = choices && choices.filter(
    vehicle => vehicle && (!selectedVehiclesIds.includes(vehicle.id) ||
      (scopedFormData ? scopedFormData.vehicleId === vehicle.id : false)),
  );

  return (getSource && !isLoading) && (
    <LazyLoadAutoCompleteInput
      label="Veículo"
      source={getSource("vehicleId")}
      format={choice => choice.licensePlate}
      style={{ width: 160, marginRight: 8 }}
      reference="vehicles"
      defaultChoices={availableVehicles}
      defaultId={scopedFormData && scopedFormData.vehicleId}
      {...props}
    />
  );
}

const RouteVehicleSelect = ({ formData, getSource, scopedFormData, ...props }) => {
  const { allChoices: choices } = useChoicesContext();

  const routeVehicles = formData.vehicles || formData.driverVehicles;
  const vehiclesIds = routeVehicles ? routeVehicles.map(vehicle => vehicle && (vehicle.vehicleId)) : [];

  const availableVehicles = choices && choices.filter(
    vehicle => vehicle && (vehiclesIds.includes(vehicle.id))
  );

  return getSource && (
    <LazyLoadAutoCompleteInput
      label="Veículo"
      source={getSource("vehicleId")}
      format={choice => choice.licensePlate}
      style={{ width: 160, marginRight: 8 }}
      reference="vehicles"
      defaultChoices={availableVehicles}
      defaultId={scopedFormData && scopedFormData.vehicleId}
      record={scopedFormData}
      {...props}
    />
  )
}

const DriverList = props => {
  const ListFilters = listProps => (
    <Filter {...listProps}>
      <SearchFilter alwaysOn />
      <SelectArrayInput
        label="Status"
        source="status"
        choices={statusChoices}
        sx={{ marginBottom: 0.5 }}
        alwaysOn
      />
    </Filter>
  );

  return (
    <List {...props}
      title="Motoristas"
      filters={<ListFilters />}
      filter={getCompanyId() ? { companyId: getCompanyId(), isValet: false, isAggregated: false } : { isValet: false, isAggregated: false }}
      empty={false}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label="Nome" />
        <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
        <TextField source="cnh" label="CNH" />
        <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
        <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies" emptyText="Não definido">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="mustInformOdometer" label="Obrigar a informar odômetro" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const DriverEdit = props => {
  const { permissions } = usePermissions();
  const classes = editStyles();

  const transform = data => {
    delete data.company;
    delete data.manager;
    delete data.subCompany;

    return { ...data, driverVehicles: data.driverVehicles.map(({ driverId, ...rest }) => rest) };
  }

  return (
    <Edit title='Editar motorista' transform={transform} {...props}>
      <TabbedForm>
        <FormTab label="Geral">
          <Permissions userRoles={[UserRole.admin]}>
            <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
              <SelectInput label="Grupo de Transportadora" optionText="name" />
            </ReferenceInput>
          </Permissions>
          <Permissions userRoles={[UserRole.admin, UserRole.company]}>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  label="Transportadora"
                  source="subCompanyId"
                  reference="sub-companies"
                  filter={{ companyId: formData.companyId }}
                  sort={{ field: "name", order: "ASC" }}>
                  <SelectInput label="Transportadora" optionText="name" validate={required()} />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </Permissions>
          <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
          <CPFInput source="cpf" label="CPF" isRequired />
          <TextInput source="cnh" label="CNH" inputProps={{ maxLength: 11 }} />
          <SelectArrayInput
            label="Categoria(s) da CNH"
            source="cnhCategories"
            reference="cnhCategories"
            choices={categoriesChoices}
          />
          <DateInput
            source='cnhValidate'
            type="date"
            label="Vencimento da CNH"
            format={dateInputFormatter}
          />
          <TextInput source="register" label="Matrícula" />
          <PhoneInput source="phone" label="Telefone" inputProps={{ autoComplete: 'off' }} />
          <PasswordInput source="password" label="Senha" inputProps={{ autoComplete: 'new-password' }} validate={[minLength(6)]} />
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ReferenceInput
                  source="managerId"
                  reference="managers"
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={
                    permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                  }
                  pagination={null}
                  perPage={null}
                  emptyText={""}
                  allowEmpty
                >
                  <SelectInput label="Gestor" optionText="name" />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Status">
          <FunctionField label="Status atual" style={{ fontSize: 18, margin: 8 }} render={r => `Status atual: ${r.isSuspended ? 'Suspenso' : r.isBlocked ? 'Bloqueado' : r.isInactive ? 'Inativo' : 'Liberado'}`} />
          <BooleanInput label="Inativar Motorista" source="isInactive" />
          <BooleanInput label="Bloquear Motorista" source="isBlocked" />
          <BooleanInput label="Suspender Motorista" source="isSuspended" />
          <BooleanInput source="mustInformOdometer" label="Obrigar a informar odômetro" />
          <BooleanInput source="mustChangePassword" label="Deve alterar senha ao entrar no aplicativo?" />
        </FormTab>
        <FormTab label="Veículos">
          <ArrayInput
            label="Veículos"
            source="driverVehicles"
            className={classes.arrayInput}
          >
            <SimpleFormIterator disableReordering inline>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  return (getSource && scopedFormData) && (
                    <div className={classes.row}>
                      {formData.driverVehicles && (
                        <ReferenceInput
                          label="Veículos"
                          source={getSource("vehicleId")}
                          reference="vehicles"
                          filter={
                            permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                          }
                          perPage={false}
                          sort={{ field: "name", order: "ASC" }}>
                          <VehicleSelect formData={formData} scopedFormData={scopedFormData} getSource={getSource} validate={[required()]} />
                        </ReferenceInput>
                      )}
                      <SelectInput
                        label="Tipo de limite"
                        choices={limitTypeChoices}
                        emptyText="Nenhum"
                        emptyValue={undefined}
                        source={getSource("fillinLimitType")}
                        style={{ width: 160, marginRight: 10 }}
                        record={scopedFormData}
                      />
                    </div>
                  );
                }}
              </FormDataConsumer>
              <NumberInput
                source={'fillinLimitValue'}
                label="Limite em litros"
                step={5}
                style={{ width: 160, marginTop: 18 }}
                validate={minValue(0)}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Rotas">
          <ArrayInput
            label="Rotas"
            source="driverRoutes"
            className={classes.arrayInput}
          >
            <SimpleFormIterator disableReordering inline>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  return (getSource && scopedFormData) ? (
                    <>
                      <div className={classes.row}>
                        {formData.driverRoutes && (
                          <ReferenceInput
                            label="Rota"
                            source={getSource("routeId")}
                            reference="routes"
                            filter={
                              permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                            }
                            sort={{ field: "name", order: "ASC" }}
                            perPage={false}
                          >
                            <SelectInput
                              label="Rota"
                              optionText="name"
                              style={{ width: 160, marginRight: 10 }}
                              record={scopedFormData}
                              validate={[required()]}
                            />
                          </ReferenceInput>
                        )}
                        <ReferenceInput
                          label="Veículo"
                          source={getSource("vehicleId")}
                          reference="vehicles"
                          filter={
                            permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                          }
                          sort={{ field: "name", order: "ASC" }}
                          perPage={false}
                        >
                          <RouteVehicleSelect formData={formData} scopedFormData={scopedFormData} getSource={getSource} validate={[required()]} />
                        </ReferenceInput>
                      </div>
                    </>
                  ) : <></>;
                }}
              </FormDataConsumer>
              <NumberInput
                source={'amountLimit'}
                label="Limite em litros"
                style={{ width: 160, marginRight: 10, marginTop: 18 }}
                validate={minValue(0)}
              />
              <DateInput
                source={'departureAt'}
                type="date"
                label="Início"
                style={{ width: 160, marginRight: 10, marginTop: 18 }}
                format={dateInputFormatter}
              />
              <DateInput
                source={'arrivalAt'}
                type="date"
                label="Fim"
                style={{ width: 160, marginTop: 18 }}
                format={dateInputFormatter}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const DriverCreate = props => {
  const { permissions } = usePermissions();
  const [odometerDefault, setOdometerDefault] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = editStyles();
  const companyId = getCompanyId();
  const { identity } = useGetIdentity();

  useEffect(() => {
    if (companyId) {
      dataProvider
        .getOne(`company-config`, { id: companyId })
        .then(({ data }) => setOdometerDefault(data.driverOdometer))
        .catch(() => notify('Erro ao buscar configurações atuais', { type: 'warning' }))
    }
  }, [companyId]);

  return (
    <Create title='Adicionar motorista' {...props}>
      <TabbedForm defaultValues={{ companyId: getCompanyId() }}>
        <FormTab label="Geral">
          <Permissions userRoles={[UserRole.admin]}>
            <ReferenceInput source="companyId" reference="companies" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
              <SelectInput label="Grupo de Transportadora" optionText="name" />
            </ReferenceInput>
          </Permissions>
          <Permissions userRoles={[UserRole.admin, UserRole.company]}>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  label="Transportadora"
                  source="subCompanyId"
                  reference="sub-companies"
                  filter={{ companyId: formData.companyId }}
                  sort={{ field: "name", order: "ASC" }}>
                  <SelectInput label="Transportadora" optionText="name" validate={required()} />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </Permissions>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ReferenceInput
                  source="managerId"
                  reference="managers"
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={
                    permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                  }
                  pagination={null}
                  perPage={null}
                  emptyText={""}
                  allowEmpty
                >
                  <SelectInput label="Gestor" optionText="name" allowEmpty />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>
          <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
          <CPFInput source="cpf" label="CPF" isRequired />
          <TextInput source="cnh" label="CNH" inputProps={{ maxLength: 11 }} />
          <SelectArrayInput
            label="Categoria(s) da CNH"
            source="cnhCategories"
            alwaysOn
            choices={categoriesChoices}
          />
          <DateInput
            source='cnhValidate'
            type="date"
            label="Vencimento da CNH"
            format={dateInputFormatter}
          />
          <TextInput source="register" label="Matrícula" />
          <PhoneInput source="phone" label="Telefone" inputProps={{ autoComplete: 'off' }} />
          <PasswordInput source="password" label="Senha" inputProps={{ autoComplete: 'new-password' }} validate={[required('Senha é obrigatória'), minLength(6)]} />
          <BooleanInput source="mustInformOdometer" label="Obrigar a informar odômetro" defaultValue={odometerDefault} />
          <BooleanInput source="mustChangePassword" label="Deve alterar senha ao entrar no aplicativo?" />
        </FormTab>
        <FormTab label="Veículos">
          <ArrayInput
            label="Veículos"
            source="vehicles"
            className={classes.arrayInput}
          >
            <SimpleFormIterator disableReordering>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {

                  return getSource && (
                    <div className={classes.row}>
                      {formData.vehicles && (
                        <ReferenceInput
                          label="Veículos"
                          source={getSource("vehicleId")}
                          reference="vehicles"
                          filter={
                            permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                          }
                          sort={{ field: "name", order: "ASC" }}
                          perPage={false}
                        >
                          <VehicleSelect formData={formData} scopedFormData={scopedFormData} getSource={getSource} validate={[required()]} />
                        </ReferenceInput>
                      )}
                      <SelectInput
                        label="Tipo de limite"
                        choices={limitTypeChoices}
                        allowEmpty
                        emptyText="Nenhum"
                        emptyValue={undefined}
                        source={getSource("fillinLimitType")}
                        style={{ width: 160, marginRight: 10 }}
                      />
                      <NumberInput
                        source={getSource('fillinLimitValue')}
                        label="Limite em litros"
                        step={5}
                        style={{ width: 160, marginTop: 8 }}
                        validate={minValue(0)}
                      />
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Rotas">
          <ArrayInput
            label="Rotas"
            source="routes"
            className={classes.arrayInput}
          >
            <SimpleFormIterator disableReordering>
              <FormDataConsumer>
                {({ formData, getSource }) => {
                  return getSource && (
                    <>
                      <div className={classes.row}>
                        {formData.routes && (
                          <ReferenceInput
                            label="Rota"
                            source={getSource('routeId')}
                            reference="routes"
                            filter={
                              permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                            }
                            sort={{ field: "name", order: "ASC" }}>
                            <SelectInput
                              label="Rota"
                              optionText="name"
                              style={{ width: 160, marginRight: 10 }}
                              validate={[required()]}
                            />
                          </ReferenceInput>
                        )}
                        <ReferenceInput
                          label="Veículo"
                          source={getSource("vehicleId")}
                          reference="vehicles"
                          filter={
                            permissions && permissions.includes(PERMISSIONS.ADMIN) ? { companyId: formData.companyId } : { companyId: getCompanyId() }
                          }
                          sort={{ field: "name", order: "ASC" }}
                          perPage={false}
                        >
                          <RouteVehicleSelect formData={formData} getSource={getSource} validate={[required()]} />
                        </ReferenceInput>
                        <NumberInput
                          source={getSource('amountLimit')}
                          label="Limite em litros"
                          style={{ width: 160, marginRight: 10, marginTop: 8 }}
                          validate={minValue(0)}
                        />
                        <DateInput
                          source={getSource('departureAt')}
                          type="date"
                          label="Início"
                          style={{ width: 160, marginRight: 10, marginTop: 8 }}
                          format={dateInputFormatter}
                        />
                        <DateInput
                          source={getSource('arrivalAt')}
                          type="date"
                          label="Fim"
                          style={{ marginTop: 8 }}
                          format={dateInputFormatter}
                        />
                      </div>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create >
  );
}

const DriverShow = props => {
  const { permissions } = usePermissions();
  return (
    <Show
      {...props}
      title="Motoristas"
    >
      <TabbedShowLayout>
        <Tab label="Resumo">
          <TextField source="name" label="Nome" />
          <FunctionField render={record => formatCpf(record.cpf)} label="CPF" />
          <TextField source="cnh" label="CNH" />
          <ArrayField source="showCnhCategories" label="Categoria(s) da CNH">
            <SingleFieldList linkType={false}>
              <ChipField source="category" />
            </SingleFieldList>
          </ArrayField>
          <DateField source="cnhValidate" label="Vencimento da CNH" emptyText="Não definido" />
          <TextField source="register" label="Matrícula" emptyText="Não definida" />
          <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
          <ReferenceField label="Gestor" source="managerId" reference="managers" emptyText="Não definido" link={false}>
            <TextField source="name" />
          </ReferenceField>
          {
            permissions && permissions.includes(PERMISSIONS.ADMIN) &&
            <ReferenceField label="Grupo de Transportadora" source="companyId" reference="companies">
              <TextField source="name" label="Transportadora" />
            </ReferenceField>
          }
          <ReferenceField label="Transportadora" source="subCompanyId" reference="sub-companies">
            <TextField source="name" />
          </ReferenceField>
        </Tab>
        <Tab label="Veículos">
          <ReferenceManyField
            reference="vehicles"
            target="driverId"
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <VehicleDatagrid bulkActionButtons={false} permissions={permissions} />
          </ReferenceManyField>
        </Tab>
        <Tab label="Rotas">
          <ReferenceManyField
            reference="routes"
            target="driverId"
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <RouteDatagrid permissions={permissions} />
          </ReferenceManyField>
        </Tab>
        <Tab label="Abastecimentos">
          <ReferenceManyField
            reference="fillins"
            target="driverId"
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <FillinDatagrid permissions={permissions} />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const DriverRoutesDatagrid = () => (
  <SimpleShowLayout>
    <ArrayField source="driverRoutes" label="Registros">
      <Datagrid>
        <ReferenceField
          label="Veículo"
          reference="vehicles"
          source="vehicleId"
        >
          <TextField source="name" label="Veículo" />
        </ReferenceField>
        <DateField
          source="departureAt"
          type="date"
          label="Início"
          format={dateInputFormatter}
        />
        <DateField
          source="arrivalAt"
          type="date"
          label="Fim"
          format={dateInputFormatter}
        />
        <NumberField source="amountLimit" label="Limite em Litros" />
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
);

export const DriverVehiclesDatagrid = () => (
  <SimpleShowLayout>
    <ArrayField source="driverVehicles" label="Registros">
      <Datagrid bulkActionButtons={false}>
        <FunctionField render={record => translateLimitType(record.fillinLimitType)} label="Tipo de limite" />
        <NumberField source="fillinLimitValue" label="Limite em Litros" />
      </Datagrid>
    </ArrayField>
  </SimpleShowLayout>
);

const resource = {
  edit: DriverEdit,
  list: DriverList,
  create: DriverCreate,
  show: DriverShow,
  name: 'drivers',
  permissions: [PERMISSIONS.LIST_DRIVERS]
};

export default resource;