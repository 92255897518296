import InvoiceAddIcon from '@mui/icons-material/NoteAddSharp';
import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  Filter,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TopToolbar
} from 'react-admin';
import { List, Permission, Permissions } from "../../components";
import { PERMISSIONS } from "../../constants";
import { UserRole } from '../../providers/authProvider';

const ListActions = () => (
  <TopToolbar>
    <Permissions userRoles={[UserRole.admin, UserRole.chain, UserRole.station]}>
      <CreateButton label="Criar Fatura" icon={<InvoiceAddIcon />} />
    </Permissions>
  </TopToolbar>
);

const FilterList = (props: any) => (
  <Filter {...props}>
    <Permissions userRoles={[UserRole.admin, UserRole.company]} alwaysOn>
      <ReferenceInput
        reference="chains"
        source="chainId"
        sort={{ field: 'name', order: 'ASC' }}
        //@ts-ignore
        perPage={null}
      >
        <SelectInput optionText="name" label="Rede" emptyText="Todos" style={{ minWidth: 220 }} />
      </ReferenceInput>
    </Permissions>
    <Permissions userRoles={[UserRole.admin]} alwaysOn>
      <ReferenceInput
        reference="companies"
        source="companyId"
        sort={{ field: 'name', order: 'ASC' }}
        //@ts-ignore
        perPage={null}
      >
        <SelectInput optionText="name" label="Grupo de Transportadora" emptyText="Todos" style={{ minWidth: 220 }} />
      </ReferenceInput>
    </Permissions>
  </Filter>
);

const InvoiceList = () => {

  return (
    <Permissions permissions={[PERMISSIONS.CREATE_INVOICE]}>
      <List
        title="Notas Fiscais"
        exporter={false}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<FilterList />}
        empty={false}
      >
        <Datagrid
          resource="bills"
          rowClick="show"
          bulkActionButtons={false}
        >
          {
            Permission({
              permission: PERMISSIONS.ADMIN,
              children: (
                <ReferenceField label="Rede" source="chainId" reference="chains" emptyText="Não definido">
                  <TextField source="name" />
                </ReferenceField>
              )
            })
          }
          <ReferenceField source="subCompanyId" label="Transportadora" reference="sub-companies" emptyText="Não definido">
            <TextField source="name" />
          </ReferenceField>
          <NumberField
            source="totalInvoiceValue"
            label="Valor Total"
            locales="pt-BR"
            textAlign="center"
            emptyText="Não informado"
            options={{
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <TextField source="billNumber" label="Número do Boleto" emptyText="Não informado" textAlign="center" />
          <NumberField
            source="billValue"
            label="Valor do boleto"
            locales="pt-BR"
            textAlign="center"
            emptyText="Não informado"
            options={{
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
          />
          <DateField source="billExpiration" label="Vencimento do boleto" emptyText="Não informado" textAlign="center" />
          <DateField source="createdAt" label="Enviado em" showTime textAlign="center" />
          <TextField source="numericId" label="Número da Fatura" textAlign="center" />
          {/* <SelectField
            label="Status"
            source="status"
            choices={[{ label: 'Pago', value: 'paid' }, { label: 'Pendente', value: 'pending' }]}
            optionText="label"
            optionValue="value"
            textAlign="center"
          /> */}
          <Permissions userRoles={[UserRole.admin, UserRole.chain]}>
            <DeleteButton />
          </Permissions>
        </Datagrid>
      </List>
    </Permissions>
  );
};

export default InvoiceList;