import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { BooleanField, BooleanInput, ChipField, Datagrid, DateField, DateInput, Filter, FormDataConsumer, FunctionField, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, useGetIdentity, useListContext } from 'react-admin';
import { CustomizableDatagrid, FillinStatusField, LazyLoadAutoCompleteInput, ListPagination, Permission, Permissions, ReportList } from '../../components';
import { UserRole } from '../../providers/authProvider';
import { formatCpf, getChainId, getCompanyId } from '../../utils/utils';
import { FillinReviewStatus, FillinStatus } from '../../models';
import dayjs from 'dayjs';
import { useRowClick } from '../../hooks/useRowClick';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const FillinList = () => {
  const classes = useStyles();
  const props = useListContext();
  const { identity } = useGetIdentity();
  const { handleRowClick } = useRowClick();

  return (
    <>
      <Typography className={classes.title}>Abastecimentos</Typography>
      <CustomizableDatagrid
        classes={{ headerCell: classes.headerCell }}
        resource={props.resource}
        data={props.data}
        rowClick={(id, resource, record) => handleRowClick('show', id, resource, record)}
        defaultColumns={['stationId', 'contractId', 'value', 'amount', 'pricePerLiter', 'oldPricePerLiter', 'fuelId', 'receivedAt', 'deliveryId', 'invoiceId', 'status']}
        bulkActionButtons={false}
      >
        <ReferenceField
          label="Posto"
          emptyText="Posto não identificado"
          source="stationId"
          basePath="stations"
          reference="stations"
        >
          <TextField
            source="name" />
        </ReferenceField>
        <Permissions label="Rede" emptyText="Não identificada" userRoles={[UserRole.admin, UserRole.company]}>
          <ReferenceField source="chainId" basePath="chains" reference="chains" link={null}>
            <TextField label="Rede" emptyText="Não identificada" source="name" />
          </ReferenceField>
        </Permissions>
        <Permissions emptyText="Não identificado" label="Frentista" userRoles={[UserRole.admin, UserRole.chain]}>
          <ReferenceField source="attendantId" basePath="station-attendants" reference="station-attendants" link="show">
            <TextField emptyText="Não identificado" label="Frentista" source="name" />
          </ReferenceField>
        </Permissions>
        <ReferenceField label="Veículo" emptyText="Não identificado" source="vehicleId" basePath="vehicles" reference="vehicles" link="show">
          <TextField label="Veículo" emptyText="Não identificado" source="licensePlate" />
        </ReferenceField>
        <ReferenceField label="Motorista" emptyText="Não identificado" source="driverId" basePath="drivers" reference="drivers" link="show">
          <TextField label="Motorista" emptyText="Não identificado" source="name" />
        </ReferenceField>
        <TextField label="Odômetro" source="odometer" />
        <ReferenceField source="contractId" reference="contracts" label="Contrato" textAlign="center" emptyText="--" link={false}>
          <TextField source="code" />
        </ReferenceField>
        <NumberField
          source="value"
          label="Valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="amount"
          label="Litros"
          textAlign="center"
          emptyText="0"
          options={{
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="pricePerLiter"
          label="Preço com desconto"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <NumberField
          source="oldPricePerLiter"
          label="Preço normal"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <FunctionField
          label="Desconto"
          textAlign="center"
          render={record =>
            `${(record.oldPricePerLiter && record.pricePerLiter ? (record.oldPricePerLiter - record.pricePerLiter) * (record.amount || 0) : 0)
              .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        />
        <ReferenceField
          label="Combustível"
          emptyText="Não identificado"
          source="fuelId"
          basePath="fuels"
          reference="fuels"
          link={identity?.role === UserRole.admin && "show"}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField source="deliveryId" label="Identificador" render={record => record.status === "approved" ? record.deliveryId : ""} />
        <DateField source="receivedAt" label="Data" showTime locales="pt-BR" />
        <BooleanField source="invoiceId" label="Nota Fiscal" looseValue />
        <ReferenceField source="billId" link="show" label="Fatura" reference="bills" emptyText="--" basePath="bills">
          <TextField source="numericId" />
        </ReferenceField>
        <ReferenceField
          label="Aprovado Por"
          emptyText="--"
          source="approvedBy"
          basePath="company-employees"
          reference="company-employees"
        >
          <TextField source="name" />
        </ReferenceField>
        <FillinStatusField label="Status" />
        <DateField source="approvedAt" label="Aprovado em" emptyText="--" showTime locales="pt-BR" />
      </CustomizableDatagrid>
    </>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <Permissions userRoles={[UserRole.admin, UserRole.chain]} label="Postos">
        <ReferenceArrayField emptyText="Todos" source="stationIds" textAlign="center" basePath="stations" reference="stations" link={false}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]} label="Motorista">
        <ReferenceField emptyText="Todos" source="driverId" textAlign="center" basePath="drivers" reference="drivers" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Permissions>
      <Permissions userRoles={[UserRole.admin, UserRole.company]} label="Motoristas Agregados" >
        <BooleanField source="isAggregated" />
      </Permissions>
      <ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <FunctionField source="deliveryId" label="Identificador" render={record => record.status === "approved" ? record.deliveryId : ""} />
      <FunctionField source="hasInvoice" label="Nota Fiscal" render={record => record.hasInvoice === true ? 'Com Nota' : record.hasInvoice === false ? 'Sem Nota' : 'Todos'} />
      <FunctionField source="manuallyAdded" label="Tipo" render={record => record.manuallyAdded === true ? 'Contingência' : record.manuallyAdded === false ? 'Automático' : 'Todos'} />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const FillinReport = (props) => {
  const { identity } = useGetIdentity();

  const filterValue = identity?.role === UserRole.chain ? { chainId: getChainId() }
    : (identity?.role === UserRole.company ? { companyId: getCompanyId() } : {});

  filterValue.stationReviewStatus = FillinReviewStatus.approved;

  const ListFilters = () => {
    const { filterValues } = useListContext();

    return (
      <Filter>
        <DateInput source="from" label="De" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).startOf('day').toISOString()} />
        < DateInput source="to" label="Até" sx={{ marginBottom: 0.5 }} options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => moment(value).endOf('day').toISOString()} />
        {
          Permissions({
            userRoles: [UserRole.admin, UserRole.company],
            children: (
              <ReferenceInput
                label="Rede"
                source="chainId"
                reference="chains"
                sort={{ field: "name", order: "ASC" }}
                emptyText="Todos"
                alwaysOn
              >
                <SelectInput
                  emptyText="Todos"
                  label="Rede"
                  optionText={'name'}
                />
              </ReferenceInput>
            )
          })
        }
        {
          Permissions({
            userRoles: [UserRole.admin, UserRole.chain, UserRole.company],
            children: (
              <ReferenceArrayInput
                source="stationIds"
                reference="stations"
                sort={{ field: "name", order: "ASC" }}
                filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : { chainId: filterValues.chainId || undefined}}
                alwaysOn
                perPage={null}
              >
                <SelectArrayInput
                  label="Posto"
                  optionText="name"
                />
              </ReferenceArrayInput>
            )
          })
        }
        {
          Permissions({
            userRoles: [UserRole.admin],
            children: (
              <ReferenceInput
                label="Grupo de Transportadora"
                source="companyId"
                reference="companies"
                sort={{ field: "name", order: "ASC" }}
                emptyText="Todos"
                alwaysOn
              >
                <SelectInput
                  emptyText="Todos"
                  label="Transportadora"
                  optionText={'name'}
                />
              </ReferenceInput>
            )
          })
        }
        {
          Permissions({
            userRoles: [UserRole.admin, UserRole.company],
            children: (
              <ReferenceInput
                source="vehicleBaseId"
                reference="vehicle-bases"
                sort={{ field: "name", order: "ASC" }}
                filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
                alwaysOn
                perPage={null}
              >
                <SelectInput
                  optionText={'name'}
                  label="Filial"
                  emptyText="Todos"
                />
              </ReferenceInput>
            )
          })
        }
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 650, justifyContent: 'space-between' }} alwaysOn>
          <FormDataConsumer>
            {({ formData }) => (
              <LazyLoadAutoCompleteInput
                label="Motorista"
                reference="drivers"
                source="driverId"
                format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
                style={{ minWidth: 400 }}
                size={"small"}
                filter={{ isAggregated: formData.isAggregated || false }}
              />
            )}
          </FormDataConsumer>
          <BooleanInput source='isAggregated' label={'Motoristas Agregados'} />
        </Box>
        <ReferenceInput label="Produto" source="fuelId" reference="fuels" sort={{ field: "name", order: "ASC" }} emptyText="Todos" alwaysOn>
          <SelectInput
            emptyText="Todos"
            label="Produto"
            optionText={'name'}
          />
        </ReferenceInput>
        <FunctionField source="deliveryId" label="Identificador" render={record => record.status === "approved" ? record.deliveryId : ""} />
        <SelectInput
          source="hasInvoice"
          label="Nota Fiscal"
          choices={[
            { name: "Sem nota", value: false },
            { name: "Com nota", value: true },
          ]}
          emptyText="Todos"
          optionText="name"
          optionValue="value"
          allowEmpty
          alwaysOn
        />
        <SelectInput
          label="Tipo"
          source="manuallyAdded"
          choices={[
            { name: "Contingência", value: true },
            { name: "Automático", value: false }
          ]}
          emptyText="Todos"
          optionText="name"
          optionValue="value"
          allowEmpty
          alwaysOn
        />
        <LazyLoadAutoCompleteInput
          label="Placa do Veículo"
          source="licensePlate"
          format={choice => choice.licensePlate}
          style={{ minWidth: 220 }}
          reference="vehicles"
          alwaysOn
        />
      </Filter>
    );
  };

  const fillinsExporter = async (data, fetchRelatedRecords) => {
    let fillinInfo = data;

    const relations = [
      { field: 'fuelId', resource: 'fuels' },
      { field: 'stationId', resource: 'stations' },
      { field: 'chainId', resource: 'chains' },
      { field: 'vehicleId', resource: 'vehicles' },
      { field: 'driverId', resource: 'drivers' },
      { field: 'subCompanyId', resource: 'sub-companies' },
      { field: 'approvedBy', resource: 'company-employees' },
    ];

    if (identity.role === UserRole.chain) {
      relations.push({ field: 'attendantId', resource: 'station-attendants' })
    }

    const fetchData = await fetchRelatedRecords(relations, fillinInfo);

    const getData = (resource) => {
      return fetchData.find(({ resource: res }) => res === resource)?.results || [];
    }

    fillinInfo = fillinInfo.map(fillin => {
      const { chainId, fuelId, stationId, driverId, subCompanyId, attendantId, vehicleId, approvedBy, ...fillinRest } = fillin;
      const attendant = getData('attendants').find(({ id }) => id === attendantId);
      const driver = getData('drivers').find(({ id }) => id === driverId);
      const vehicle = getData('vehicles').find(({ id }) => id === vehicleId);
      const chain = getData('chains').find(({ id }) => id === chainId);
      const station = getData('stations').find(({ id }) => id === stationId);
      const subCompanie = getData('sub-companies').find(({ id }) => id === subCompanyId);
      const companyEmployee = getData('company-employees').find(({ id }) => id === approvedBy);
      const fuel = getData('fuels').find(({ id }) => id === fuelId);

      return {
        posto: station ? station.name : 'Não identificado',
        rede: chain ? chain.name : 'Não identificado',
        veiculo: vehicle ? vehicle.licensePlate : 'Não identificado',
        motorista: driver ? driver.name : 'Não identificado',
        transportadora: subCompanie ? subCompanie.name : 'Não identificado',
        ...(identity.role === UserRole.chain ? {
          frentista: attendant ? attendant.name : 'Não identificado',
        } : {}),
        odometro: fillinRest.odometer,
        valor: fillinRest.value,
        litros: fillinRest.amount,
        preco_do_acordo: Number(fillinRest.pricePerLiter).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        preco_do_bico: Number(fillinRest.oldPricePerLiter).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        desconto: Number(fillinRest.oldPricePerLiter && fillinRest.pricePerLiter ? (fillinRest.oldPricePerLiter - fillinRest.pricePerLiter) * (fillinRest.amount || 0) : 0)
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        combustivel: fuel ? fuel.name : 'Não identificado',
        identificador: fillinRest.status === "approved" ? fillinRest.deliveryId : '',
        data: fillinRest.receivedAt ? dayjs(fillinRest.receivedAt).format('DD/MM/YYYY HH:mm') : '',
        nota_fiscal: fillinRest.invoiceId ? 'sim' : 'não',
        status: FillinStatus.translateStatus(fillinRest.status),
        ...(identity.role === UserRole.company ? {
          aprovadoPor: companyEmployee ? companyEmployee.name : '',
          aprovadoEm: fillinRest.approvedAt ? dayjs(fillinRest.approvedAt).format('DD/MM/YYYY HH:mm') : '',
        } : {}),
      };
    });

    return fillinInfo;
  }

  return (
    <ReportList
      {...props}
      basePath="fillins"
      title="Abastecimentos"
      resource="fillins"
      filters={<ListFilters />}
      sort={{ field: 'receivedAt', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{
        from: moment().subtract(1, 'month').startOf('day').toISOString(),
        to: moment().endOf('day').toISOString(),
        isAggregated: false,
      }}
      filter={filterValue}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      fileName='relatorio_de_abastecimentos'
      customExporter={fillinsExporter}
      hasSummary={false}
      titleOnPrint="Relatório de Abastecimentos"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <FillinList />
    </ReportList>
  );
};

export default FillinReport;